/* Import Google font - Poppins */
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap"); */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.body {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.s-container {
  position: relative;
  max-width: 950px;
  width: 100%;
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.s-container header {
  font-size: 1.5rem;
  color: #333;
  font-weight: 500;
  text-align: center;
}
.s-container .form {
  margin-top: 30px;
}
.form .input-box {
  width: 100%;
  margin-top: 20px;
}

textarea{
  border: 1px solid rgb(197, 192, 192);
  border-radius: 5px;
  padding: 5px;
  font-size: 17px;
}

input::placeholder{
    opacity:0;
}
label{
    margin-bottom:15px;
    position:relative;
}
.span{
    position:absolute;
    top:0;
    left:0;
    transform:translateY(0px);
    font-size:0.825em;
    transition-duration:300ms;
}
label:focus-within > .span,
input:not(:placeholder-shown) + .span{
    color:var(--color-soft);
    transform:translateY(-22px);
}

.input-box label {
  color: #333;
}
.form :where(.input-box input, .select-box) {
  position: relative;
  height: 30px;
  width: 100%;
  outline: none;
  font-size: 1rem;
  color: #707070;
  margin-top: 8px;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 0 15px;
}
.input-box input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.form .column {
  display: flex;
  column-gap: 25px;
  margin-bottom: 5px;
}

.gender-box h3 {
  color: #333;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 8px;
}
.address :where(input, .select-box) {
  margin-top: 15px;
}
.select-box select {
  height: 100%;
  width: 100%;
  outline: none;
  /* border: none; */
  color: #707070;
  font-size: 1rem;
}
.form button {
  height: 55px;
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 30px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background: var(--color-blue);
}
.form button:hover {
  background: rgb(85, 154, 227);
}

.localisation,.categorie{
  margin-top: 5px;
}

.err-msg1{
  display: flex;
  justify-content: center;
  color: red;
}

.erromsg, .err-msg{
  text-align: center;
    color: red;
    font-size: 12px;
}

.alt-desc{
  color: green;
  font-size: 15px;
}

/*Responsive*/
@media screen and (max-width: 500px) {
  .form .column {
    flex-wrap: wrap;
  }
  .form :where(.gender-option) {
    row-gap: 15px;
  }
}