.userProfile .cover-photo {
    height: 200px;
    aspect-ratio: 1/1;
    width: 100%;
    overflow: hidden;
    /* margin-top: 3.6rem; */
}

.profile-info {
    width: 90%;
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    gap: .7rem;
    background: var(--color-card);
    padding: 20px;
    border-radius: 1rem;
    text-align: center;
    position: relative;
    margin: 1rem auto;
    margin-top: -100px;
}

.profile-info>img {
    border-radius: 50%;
    aspect-ratio: 1/1;
    width: 7em;
    /* margin-top: -80px; */
    margin: -80px auto;
    margin-bottom: 0px;
    border: 4px solid white;
    background: #ffffff;
}

.profile-button {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    gap: 1rem;
}

.intro {
    width: 100%;
    border-top: 1px solid gray;
    text-align: left;
    color: black;
    font-weight: 800;
}

.infos_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 10px;
    /* border: 1px solid black; */
    /* padding: 20px; */
    margin-top: 15px;
}

.info_item {
    /* background-color: #3498db; */
    /* color: #fff; */
    text-align: center;
    /* border: 1px solid black; */
    /* padding: 20px; */
    /* border-radius: 5px; */
    font-size: .9em;
    display: flex;

}

.info_item .item_left {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info_item .item_right {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.info_item span.label {
    color: grey;
    font-size: .9em;
}

.infos_box {
    /* text-align: left; */
    color: black;
    /* border: 1px solid black; */

}

.settings {
    position: absolute;
    /* margin: 0;
    padding: 0; */
    left: 2.5rem;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: whitesmoke;
    border-radius: 5px;
    box-shadow: black 2px 2px 5px;
}

.user-name h3{
    text-transform: uppercase;
}