*{font-family: "Poppins", sans-serif;
    padding: 0;
    margin: 0;
}

.pwd-container {
    width: 100vw;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f1f5f9;
}

/* Styling for the card content */
.pwd-card {
    width: 320px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 8px;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}
    
.pwd-card-header {
    border-bottom: 2px solid #e9e9e9;
    padding: 15px 18px;
    background-color: #ffffff;
}
    
.pwd-card-body {
    padding: 0rem 20px 16px 20px;
}

.pwd-title {
    font-size: 16px;
    font-weight: 500;
    color: #343434;
    margin: 0;
}
    
.pwd-label {
    font-size: 15px;
    color: #404040;
    margin: 0 0 6px 0;
    margin-top: 1rem;
}

/* styling for input */
.pwd-input {
    width: 255px;
    font-size: 12px;
    letter-spacing: 1px;
    padding: 10px 12px;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #eaeaea;
    box-shadow: 50px 10px 30px 0px rgba(0, 0, 0, 0.08);
    }
    
::placeholder {
    font-size: 12px;
    font-weight: 400;
    color: #e8e8e8;
}
    
.pwd-input:focus {
    outline: none;
    border: 1.5px solid #6140ac;
}

/* Styling for buttons */
.btn-pwd {
    width: 100%;
    display: inline;
    background-color: var(--color-blue);
    border-radius: 8px;
    padding: 10px 20px;
    border: none;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    margin-top: 15px;
}

.btn-pwd:hover {
    background-color: #3276ec;
}

/* Styling for error & success messages */
.pwd-err-message {
    font-size: 12px;
    font-weight: 500;
    color: red;
    margin-top: 6px;
}

.pwd-success-message {
    font-size: 12px;
    font-weight: 500;
    color: green;
    margin-top: 16px;
}