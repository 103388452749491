.separate{
    width: 100%;
    background: var(--color-card);
    /* border-bottom: 1; */
    margin-bottom: 10px;
    height: 3em;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    justify-content: space-between;

}
.counter{
    height: 35px;
    width: 35px;
    background: gainsboro;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

}