.container-card {
    max-width: 100%;
    margin: 20px auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.about-text{
    margin-top: 35px;
}

.card {
    width: 20rem;
    min-height: 100%;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
}

.pdcvep{
    color: var(--color-primary);
    font-weight: 800;
    margin: 0;
}

.card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card img {
    width: 100%;
    max-height: 200px;
}

.card h3{
    margin: 10px 0;
}
.card-text {
    padding: 15px;
    background-color: #ffffff;
}

h2 {
    margin-top: 0;
}

.sponsor {
    padding: 20px;
    text-align: center;
}

.sponsor-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.sponsor-logo {
    max-width: 120px;
    margin: 0 10px;
    transition: transform 0.3s;
}

.sponsor-logo:hover {
    transform: scale(1.2);
}

@media screen and (max-width: 600px) {
    .sponsor-logos {
        flex-direction: column;
        align-items: center;
    }

    .sponsor-logo {
        margin: 10px 0;
    }
}