.navbar{
    display: flex;
    justify-content: space-between;
    height: 10vh;
    padding: 0 15px;
    border: none;
    background: transparent;
    z-index: 10;
}
.navbar li{
    padding: 0.3rem;
    width: auto;
    text-align: center;
    border-radius: 5px;
    transition: .5s ease;
}
.navbar li a{
    color: white;
    font-weight: 500;
}

.navbar ul li{
    background: rgba(93, 223, 182, 0.5);
    transition: 0.5 ease-in-out;
}
.navbar ul li:hover{
    background: var(--color-primary);
}
.navbar ul li a{
    color: white;
}
.navbar .active{
    background: var(--color-primary);
}
.navbar li.active a{
    color: white;
}
.logo .image{
    width: 4.5rem;
    height: 4.5rem;
}

.navbar {
    background: transparent;
}

  @media screen and (max-width: 415px) {
    .home-link {
      display: none;
    }

    .logo .image{
        width: 2.2rem;
        height: 2.2rem;
    }

    .navbar li{
        padding: 0 0.1rem;
        font-size: 14px;
    }

    .navbar ul{
        margin-right: -25px;
    }
  }



