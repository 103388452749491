.main-container{
    background: white;
}

.about{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 2em auto;
}
.about-content{
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.about_item{
    height: 21em;
    width: 30%;
    background: var(--color-bg);
    border-radius: 15px;
    padding: 10px;
    transition: .5s ease;
    color: rgb(60, 60, 60);
}
.about .text{
    border-bottom: 1px solid black;
    width: 30%;
}
.about_item .item_title{
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(176, 176, 176);
    color: var(--color-gray);
    font-weight: bold;
}
.about_item:hover{
    background: var(--color-blue);
}