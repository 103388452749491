.comments{
    width: 100%;
    background: var(--color-bg);
    padding: 0.3rem;
    border-radius: .5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem 0;
}

.comments .user-coment{
    display: flex;
}

.comments .writebox{
    width: 100%;
    justify-content: space-between;

}
.comments .writebox textarea{
    width: 100%;
    background: white;
    border: 1px solid var(--color-soft);
    padding: 0.5rem;
    border-radius: .5rem;
    color: var(--color-text);
}

.btn-cmt{
    color: var(--color-blue);
    font-size: 25px;
    background-color: rgb(238, 233, 233);
}

.delete-icon{
    font-size: 12px;
    color: rgb(86, 83, 83);
}