.feeds{
    display: flex;
    align-items: center;
    gap: 30px;
    flex-direction: column;
    
}
.feed{
    padding: 20px;
    margin-bottom: 8px;
    border-radius: .2rem;
    background: var(--color-card);
    width: 100%;
    overflow: hidden;
    max-height: auto;
    z-index: 0;
}
.feed .top-content{
    margin-bottom: 3px;
    display: flex;
    justify-content: space-between;
}
.feed p{
    margin-bottom: 1rem;
}
.mid-content{
    height: 60%;
    padding-top: 15px;
    z-index: 0;
    position: relative;
}
.feed .mid-content img{
    height: 100%;
    border-radius: .2rem;
    aspect-ratio: 1/1;
    width: 100%;
    position: relative;
}

.mid-content-text{
    text-wrap: wrap;
}

.feed .bottom-content{
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 1rem;
    border-top: 1px solid #e5e5e5;
    padding-top: 10px;
}

.vente-post{
    display: flex;
    justify-content: space-between;
}
.vente-post .post-vente{
    color: var(--color-primary);
}

.coment-icon{
    cursor: pointer;
}
.btn-wats{
    height: 2rem;
    width: 1.5rem;
    background-color: var(--color-blue);
    border-radius: 5px;
    padding: 3px 3px 0 3px;
}

.btn-wats:hover{
    background-color: var(--color-primary);
}

.action-item{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.defilBtn .defilBtn_ch{
    color: var(--color-gray);
    background: white;
    opacity: 15;
    width: 2em;
    height: 2em;
    border-radius: 50%;
}
.theName:hover{
    text-decoration: underline;
}

.dot{
    background: transparent;
    color: rgb(162, 162, 162);
}
.carousel__dot--selected{
    color: var(--color-primary);
}

.custom-bullets {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.custom-bullets li {
  margin: 0 5px;
}

.custom-bullets button {
  width: 10px;
  height: 10px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.custom-bullets .awssld__bullets--active button {
  background-color: red;
}

.awssld__bullets{
    background: transparent;
    width: auto;
    position: absolute;
    top: 85%;
    border-bottom: none;
    z-index: 2;
}

.awssld__container{
    background: var(--color-card);

}

.deleteTrash:hover{
    cursor: pointer;
}


/* src/FilterBar.css */
.filter-bar {
    border-radius: .5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 10px 0;
    height: 2em;
  }
  select{
    width: auto;
    height: 100%;
    cursor: pointer;
    border-radius: 5px;
  }


@media (max-width:768px){
    .defilBtn{
        display: none;
    }
}