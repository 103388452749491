/* src/App.css */
.manual-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
  }
  
  .manual-container h1, h2, h3 {
    color: #333;
    margin-top: 25px;
  }

  .manual-container h1{
    margin-bottom: 25px;
    text-align: center;
  }
  
  .manual-container ul {
    list-style-type: none;
  }
  
  .manual-container p {
    margin-bottom: 10px;
  }
  