.seachBox{
    position: fixed;
    z-index: 99;
    width: 59%;
    background-color: rgb(221, 216, 216);
    padding: 10px;
    border-radius: 5px;
}

.searchIcon{
    display: block;
    position: fixed;
    z-index: 99;
    margin: -42px 0 0 6.5rem;
    cursor: pointer;
}

.homeIcon{
    display: block;
    position: fixed;
    z-index: 99;
    margin: -42px 0 0 3.2rem;
    cursor: pointer;
    color: white;
}

.message{
    cursor: pointer;
    line-height: 25px;
}

@media (max-width:768px) {
    .seachBox{
        width: 95%;
    }
}

