.leftBar{
    position: sticky;
    top: 3.6rem;
    background: var(--color-card);
    height: calc(100vh - 3.6rem);
    /* overflow: auto; */
}
.leftBar::-webkit-scrollbar{
    display: none;
}
.left-container{
    width: 80%;
    margin: 0 auto;
}
.left-container .user{
    margin-top: 20px;
}
.left-container .item{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
}
.left-container .item img{
    width: 20px;
}
hr{
    background: var(--color-border);
    border: none;
    height: .4px;
    margin-bottom: 20px;
}
.others{
    color: var(--color-soft);
    font-size: .9rem;
}

/* MEdia Queries */

@media (max-width:1024px){
    .left-container{
        /* width: fit-content; */
        padding-left: 1rem;
    }
    .left-container h4,.left-container .others{
        display: none;
    }
}
/* @media (max-width:991px){
    .leftBar{
        display: none;
    }
} */
@media (max-width:768px){
    .leftBar{
        display: none;
    }
}