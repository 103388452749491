.car-body {
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    border-radius: 5px;
    /* height: 15em; */
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
}
.img_pod {
    flex-shrink: 0; 
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 20px;
}

.img_pod img{
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
}
 
.container_copy {
    padding: 20px;
}

.btn_primary {
    background-color: var(--color-primary);
    color: #fff;
    padding: 5px 10px;
    text-decoration: none;
    transition: background-color 0.3s ease;
    border-radius: 5px;
    float: right;
}

.tiltle{
    /* text-align: center; */
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
    position: sticky;
}
.container_copy .card-text{
  font-size: 1rem;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
} 
.container_copy{
  width: 100%;
}
.more{
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: flex-end;
}
.u_desc{
  width: 100%;
  height: 4.8em;
  overflow: hidden;
}
.u_desc p{
  text-overflow: ellipsis;
}
.u_name{
  font-weight: 800;
}
.u_council{
  color: gray;
}
.filter{
  display: none;
  cursor: pointer;
}

.filter_menu{
  background-color: #fff;
  width: 15rem;
  position: absolute;
  border-radius: 10px;
  right: 0;
  top: 30px;
  /* box-sizing: border-box; */
  box-shadow: 10px ;
}




@media (max-width:768px) {
  .filter{
    display: block;
  }
}

@media (max-width: 500px) {
  .car-body{
    display: block;
  }
  .img_pod img{
    margin: 15px 0 0 10px;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
  }
}

@media (max-width: 600px) {
  .blog_post {
      flex-direction: column;
  }
  
  .img_pod {
      width: 30%;
      margin-bottom: 10px;
  }
  
  .container_copy {
      padding: 10px;
  }
}