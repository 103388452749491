.signup{
    background: var(--color-primary);
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.signup .card{
    display: flex;
    flex-direction: row-reverse;
    width: 580px;
    background: white;
    border: 1px solid white;
    border-radius: .6rem;
    overflow: hidden;
    padding: 0;
}
.signup .card .left{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: .8rem;
    padding: 20px;
}
.signup .card p{
    font-size: .7rem;
}
.signup .card span{
    color: var(--color-primary);
}
.signup .card h2{
    color: var(--color-primary);
}
.signup .card .right{
    flex: 1;
    background: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    padding: .5rem;
}
.signup form input{
    padding: 1rem 0;
    width: 90%;
    background: transparent;
    border: transparent;
    outline: none;
    border-bottom: 1px solid white;
    margin: 0 auto;
    color: white;
    font-size: 1.2rem;
}
.signup form input::placeholder{
    color: white;
    font-size: 1rem;
}
.signup form .btn{
    color: var(--color-primary);
    margin-top: 1rem;
}
@media (max-width:600px) {
    .signup .card{
        width: 90%;
        flex-direction: column-reverse;
    }
    .signup .card .right{
        padding-bottom: 20px;
    }
}