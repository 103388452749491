nav{
    height: 3.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 5px solid var(--color-border);
    /* color:var(--color-primary); */
    position: sticky;
    z-index: 1;
    background: var(--color-primary);
    top: 0;
}
.nav-container{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.nav-left{
    display: flex;
    align-items: center;
    gap: 20px;
}
.nav-container a{
    color: var(--color-primary);
    font-size: 1.1rem;
}
.nav-container .a-icon{
    color: #fff;
}
.members-logo{
    color: var(--color-primary);
    font-size: 1.8rem;
    font-weight: 400;
}
.members-logo img{
    width: 3rem;
    margin-bottom: 25px;
}


.structs{
    display: none;
}
.toggleNavMenu{
    color: white;
    background: #000;
}
.m_dropdown{
    position: absolute;
    top: 3.4em;
    right: 2em;
    
}
.b_dropdown{
    width: 95%;
    height: 18em;
    margin: auto;
    position: absolute;
    top: 3.5em;
}
.burger{
    display: none;
    cursor: pointer;
    margin-left: 10px;
}

.rigth-action{
    display: flex;
}

.rigth-action .btn-sign {
    color: white; 
    padding: 0.2rem 0.7rem;
    border: none;
    margin: 0 2px;
    cursor: pointer;
    transition: background-color 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .rigth-action .btn-sign:hover {
    background-color: white; 
    color: #025116;
    border-radius: 5px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .faSearch{
    display: none;
  }


/* Media queries */
@media (max-width:768px) {
    .toggleNavMenu{
        display: flex;
        flex-direction: column;
        width: 10em;
        height: auto;
        background: var(--color-card);
        border: 1px solid var(--color-border);
        position: absolute;
        z-index: 1000;
        top: 3.5em;
        right: -0.1em;
        margin-right: 1rem;
        border-radius: 10px;
    }
    .nav-right{
        display: flex;
        align-items: center;
        gap: 30px;
    }   
    nav .user img{
        cursor: pointer;
    }
    nav .structs{
        display: none;
        
    }
    .burger{
        display: block;
    }

    nav .newpost{
        display: none;
    }
    nav .structs{
        display: block;
    }
    .nav-container a{
        font-size: 1rem;
    }
    .nav-container logo{
        font-size: 1.3rem;
    }
    .nav-container input{
        display: none;
    }
    .nav-container{
        border: none;
        padding: 0;
    }
    .nav-right,.nav-left{
        gap: 20px;
    }
    .faSearch{
        display: block;
      }

    .navSearch{
        position: absolute;
        align-items: center;
        top: 30px;
        width: 100%;
      }

    
}

@media (max-width:450px) {
    .rigth-action .btn-sign {
        padding: 0;
        font-size: 15px;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.16);
        border-radius: 5px;
        padding: 0 2px;
      }
}