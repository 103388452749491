
/* Modal styles */
.custom-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-card);
    padding: 20px;
    border: 1px solid var(--color-border);
    outline: none;
    max-width: 600px;
    width: 100%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    opacity: 0;  /* Initialisez l'opacité à 0 pour la transition d'ouverture */
    transition: opacity 0.3s ease-in-out;
    z-index: 100;
    /* display: flex; */
  }
  
  .close{
      font-size: 1.5em;
      transition: 0.5s ease-in-out;
      cursor: pointer;
      float: right;
  }
  .close:hover{
      color: red;
  }
  
  
  /* Overlay styles */
  .custom-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0); /* Transparent au début */
      opacity: 0; /* Initialisez l'opacité à 0 pour la transition d'ouverture */
      transition: opacity 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }
    
    /* Styles pour montrer la modal lorsqu'elle est ouverte */
    .custom-modal.open {
      opacity: 1;
    }
    
    /* Styles pour montrer l'overlay lorsqu'il est ouvert */
    .custom-overlay.open {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.5); /* Assombrissement complet lorsqu'ouverte */
    }
    
    


.float{
    /* display: none; */
    position: fixed;
    z-index: 1;
    top: 80%;
    left: 88%;
    transition: all 0.3s ease-in-out;
    
}
.show {
    /* z-index: 1; */
    bottom: 120px;
  }
  
  .main-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .elements {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 60px;
    right: 20px;
    opacity: 0; /* Commencez avec une opacité à 0 pour ajouter une transition */
    transition: all 0.3s ease-in-out;
  }
  
  .show .elements {
    opacity: 1;
    bottom: 0;
  }
  
  .additional-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 8px;
    margin-top: 5px;
    font-size: 16px;
    cursor: pointer;
  }
.float_btn{
    /* height: 150; */
    color: white;
    background: var(--color-blue);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
