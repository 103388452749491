@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: none;
  list-style: none;
  font-family: 'Lato', sans-serif;
  text-decoration: none;
  outline: none;
  border: none;
  user-select: none;
}

:root{
  --color-primary: #189c72;
  --text-color:#000000;
  --color-bg:#e9e5e5;
  --color-card:#ffffff;
  --color-soft:#4d4c4c;
  --color-border: lightgray;
  --color-gray: rgb(83, 83, 83);
  --color-red : #fe1212;
  --color-blue: rgb(69, 144, 223);
}

.darkmode{
  --text-color:#ffffff;
  --color-bg:#0a0a0a;
  --color-card:#111111;
  --color-soft:#aaaaaa;
  --color-border: rgb(83, 83, 83);
}
body{
  background-color: var(--color-bg);
  color: var(--color-soft);
  font-size: 1rem;
  overflow-x: hidden;
}

.container{
  max-width: 991px;
  width: 100%;
  margin : 0 auto;
}

h1,h2,h3,h4,h5{
  font-weight: 600;
  color: var(--text-color);
}
h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.2rem;
}

p {
  font-size: 1rem;
}

img{
  width: 100%;
  display: block;
  object-fit: cover;
}

.user{
  display: flex;
  align-items: center;
  gap: 10px;
}

.user img{
  width: 2.5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.user h4{
  color: var(--color-primary);
}

.user h5{
  text-transform: uppercase;}

small,p{
  color: var(--color-soft);
}

.btn{
  padding: .6rem 1.6rem;
  display: inline-block;
  width: fit-content;
  cursor: pointer;
  border: none;
  border-radius: .5rem;
  font-size: .9rem;
  font-weight: 500;
  transition: .3s ease;
}

.btn:hover{
  opacity: .7;
}

.btn-primary{
  background: var(--color-primary);
  color: white;
}

.btn-red{
  background: var(--color-red);
  color: white;
}

.btn-gray{
  background: var(--color-gray);
  color: white;
}

main{
  display: grid;
  grid-template-columns: 15% 60% 23%;
  gap: 2%;
  width: 100%;
}

.ReactModal__Content{
  position: relative;
  z-index: 10000;
}

.loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@media(max-width:1024px){
  main{
    grid-template-columns: 8% 56% 32%;
  }
}
@media(max-width:768px){
  main{
    grid-template-columns: 100%;
  }
  .container{
    width: 94%;
  }
}