.Messages{
    background: var(--color-card);
    margin: 20px auto;
    border-radius: 1rem;
    padding: 14px;
}

.message-body h5{
  text-transform: uppercase;
}

.message-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}
.messageSearch{
    background: var(--color-bg);
    display: flex;
    gap: 5px;
    align-items: center;
    border-radius: 1rem;
    padding: .7rem;
    margin-bottom: 1rem;

}
.messageSearch input{
    background: transparent;
    color: var(--color-text);
}
.message-top a{
    cursor: pointer;
    color: var(--color-primary);
}
.border-div{
    width: 100%;
    height: .1rem;
    background: var(--color-bg);
    margin-bottom: .5rem;
}
.message{
    display: flex;
    align-items: center;
    gap: 5px;
}
.message .user{
    position: relative;
    height: fit-content;
    width: fit-content;
}
.message-body{
  width: 80%;
  margin: auto;
}
.message-body p{
  overflow: hidden;
}
.green-active{
    height: 10px;
    width: 10px;
    background: var(--color-primary);
    position: absolute;
    border-radius: 50%;
    bottom: 0;
    right: 0;
    
}
.one_mess{
    /* border: 1px solid black; */
}

.profil{
    cursor: pointer;
    height: 3.8em;
    /* border-bottom: 1px solid rgb(193, 193, 193); */
    padding: .2rem;
    border-radius: 1rem;
    transition: .2s ease-in-out;
}
.profil:hover{
  /* opacity: 500; */
    background: var(--color-border);
    
}
.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
  }

  /* RegionsComponent.css */
.regions-container {
    padding-left: 0.5rem;
  }

  .regions-container ul{
    line-height: 35px;
    cursor: pointer;
    padding: 0 5px ;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .regions-container ul:hover{
    color: white;
    /* font-size: 19px;
    transition: 0.5s ease; */
    background-color: var(--color-primary);
  }

 .active {
    background-color: var(--color-primary);
    color: #ffffff;
    padding: 0 5px ;
    
  }
 .s_active {
    background-color: var(--color-border);
    color: #ffffff;
    padding: 0 5px ;
    
  }

  