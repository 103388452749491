header{
    text-align: center;
    border-bottom: 1px solid gray;
}

ul{
    list-style: none;
    padding: 0px;
}
ul li{
    display: inline-block;
    margin-right: 20px;
}
a{
    text-decoration: none;
    color: Oblack; 
}
    