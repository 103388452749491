.chatBox{
    background: var(--color-card);
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    border-radius: .5rem;
    overflow: hidden;
    margin-bottom: 20px;
}
.chatBox-top{
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid var(--color-border);
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: .7rem;
    padding: 2rem 0
}
.chatBox img{
    width: 100px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    margin-top: .5rem;
    border: 3px solid var(--color-primary);
}
.chatBox-bottom{
    background: var(--color-bg);
    width: 80%;
    margin: 1rem auto;
    padding: .8rem;
    border-radius: .8rem;
}
.chatBox-bottom form{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}
.chatBox-bottom input{
    width: 100%;
    background: transparent;
    color: var(--color-primary);
    font-size: 1rem;
}
.chatBox-bottom label input{
    display: none;
}

@media(max-width:768px){
    .chatBox-top{
        width: 90%;
    }
    .chatBox-bottom{
        width: 90%;
    }
}