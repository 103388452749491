.faq-content{
    margin: 15px 10rem;
}

.faq-content h1{
    text-align: center;
    margin-bottom: 25px;
}

.faq-content p{
    margin-bottom: 25px;
}