.l-body{
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f4f4f4;
}

.l-title{
    text-align: center;
    margin: 20px 0 50px 0;
}

.error-message{
    color: rgb(189, 30, 30);
}

.login-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 400px;
}

.form-group {
    position: relative;
    margin-bottom: 20px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 45px;
    font-size: 16px;
    transition: border-color 0.3s, box-shadow 0.3s; /* Ajout de la transition */
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); /* Ajout de l'ombre interne */
}


.form-group label {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #888;
    font-size: 14px;
    pointer-events: none;
    transition: top 0.3s, font-size 0.3s;
}

.form-group input:focus {
    border-color: #2ecc71;
}

.form-group input:valid + label,
.form-group input:focus + label {
    top: -10px;
    font-size: 12px;
    color: #230ba8;
}

.icon {
    position: absolute;
    top: 16px;
    right: 10px;
    color: #ccc;
    cursor: pointer;
}

.loading-btn{
    background-color: var(--color-primary);
    color: #fff;
    padding: 8px;
    width: 100%;
    border: none;
    border-radius: 10px;
    font-size: 16px;
}

.submit-btn {
    background-color: var(--color-blue);
    color: #fff;
    padding: 8px;
    width: 100%;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    margin-bottom: 5px;
}


.submit-btn:hover{
    background-color: rgb(85, 154, 227);
}

.create-account {
    margin-top: 20px;
    text-align: center;
    line-height: 25px;
}

.create-account a:hover {
    color: #4c00ff;
    text-decoration: none;
}

.forgetpwd a{
    color: #559ae3;
}
.forgetpwd a:hover{
    color: #4c00ff;
}