    .user {
    border: none;
    cursor: pointer;
  }

  .user .userName{
    color: white;
  }

/* sidebar */
.a-nav-menu-items{
    display: flex;
    flex-direction: column;
}
    
.a-menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}
    
.a-nav-menu {
    background-color: #060b26;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    left: -100%;
    transition: 850ms;
}

.a-nav-menu.active {
    left: 0;
    transition: 350ms;
}

.a-nav-menu-items {
    width: 100%; 
}

.a-navbar-toggle {
    background-color: #060b26;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}

span {
    margin-left: 16px;
}

/* menu */

.a-nav-container{
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.a-nav-left{
    display: flex;
    align-items: center;
    gap: 20px;
}
.nav-container a{
    color: var(--color-primary);
    font-size: 1.1rem;
}
.a-logo{
    color: var(--color-primary);
    font-size: 1.8rem;
    font-weight: 400;
}
.a-logo img{
    width: 50px;
}

.dropDown{
    position: absolute;
    margin: 6rem 0 0 1rem;
    border-radius: 5px;
}

/* Media queries */
@media (max-width:768px) {
    /* nav .user{
        display: none;
    } */
    .a-nav-container a{
        font-size: 1rem;
    }
    .a-nav-container logo{
        font-size: 1.3rem;
    }
    .a-nav-container input{
        display: none;
    }
    .a-nav-container{
        border: none;
        padding: 0;
    }
    .nav-right,.a-nav-left{
        gap: 20px;
    }

}