/* ... (autres styles) */
@media (max-width: 600px) {
    .custom-modal {
        max-width: 90%;
    }
}
  /* ... (autres styles) */

.formP {
margin-bottom: 15px;
}
.formM label {
display: block;
margin-bottom: 5px;
}

.formP input {
width: 100%;
padding: 5px;
box-sizing: border-box;
border-radius: 5px;
border: 1px solid var(--color-border);
}

.formM .postbtn{
    display: flex;
    justify-content: end;
}

.formM button {
color: white;
padding: 10px;
border: none;
border-radius: 4px;
cursor: pointer;
margin: 0 5px;
}

.formM .submit{
    background-color: var(--color-blue);
}
.formM .cancel{
    background-color: grey;
}
.formM .submit:hover{
    background-color: rgb(72, 72, 216);
}
.formM .cancel:hover{
    background-color: rgb(54, 53, 53);
}

.formM .text_input{
    height: 7em;
    width: 100%;
    resize: none;
    margin-top: 15px;
}
.formM{
    z-index: 1000;
}
.formM .previewBox{
    margin: 1rem auto;
    display: flex;
    flex-wrap: wrap;
}
.img_box img{
    width: 5rem;
    height: 5rem;
}
.formM .previewImg{
    width: 2rem;
    max-height: 3rem;
}

.sellBox{
    width: 15px;
    height: 15px;
    padding: 10px;
    background: var(--color-border);
    cursor: pointer;

}

.click{
    background: var(--color-primary);
    color: white;
}

.deleteImg{
    position: absolute;
    margin: auto;
    color: rgb(255, 151, 116);
    cursor: pointer;
    font-size: 1.5em;
}
.img_box{
    display: flex;
    justify-content: flex-start;
    margin: 2px;
}

.imgerr{
    color: red;
    text-align: center;
    font-size: 14px;
}

.postCenter{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
}

.img-post{
    background: #c2bfbf;
    width: 45%;
    padding: 3px 0 0 3px;
    margin-bottom: 5px;
    border-radius: 5px;
}

.img-post span{
    margin-right: 1.2rem;
}