.main .name{
    position: absolute;
    top: 13rem;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
    text-align: center;
    width: 100%;

}

.main{
    height: 35rem;
    position: relative;
    top: -10vh;
    margin-bottom: -90px;
    overflow: hidden;
}

.main .name p{
    color: white;
    font-weight: 800;
    font-size: 1.1rem;
}
.main .name h1{
    color: white;
    font-size: 2.5rem;
}
.main .name .btn{
    margin-top: 1rem;
}
.main span.sh1{
    text-shadow: 2px 3px #138b27;
}
.main span.sh2{
    text-shadow: 2px 3px #e41c1c;
}
.main span.sh3{
    text-shadow: 2px 3px #f2f21c;
}
.underL{
    border-bottom: 3px solid var(--color-primary);
}

@media screen and (max-width: 900px) {
    .main{
        height: 100%;
    }
    .main .name h1{
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 500px) {
    .main .name{
        margin-top: -2.5rem;
    }
    .main .name h1{
        font-size: 1.5rem;
    }
    .main .name .btn{
        padding: 0.2rem;
    }

    .main span.sh1{
        text-shadow: 1px 2px #138b27;
    }
    .main span.sh2{
        text-shadow: 1px 2px #e41c1c;
    }
    .main span.sh3{
        text-shadow: 1px 2px #f2f21c;
    }

    .underL{
        border-bottom: 1px solid var(--color-primary);
    }
}

@media screen and (max-width: 430px) {
    .main .name{
        margin-top: -3.5rem;
    }
    .main .name h1{
        font-size: 1rem;
    }
    .main .name p{
        font-size: 0.8rem;
    }
}
