.rightBar{
    height: calc(100vh - 3.6rem);
    /* background: var(--color-card); */
    position: sticky;
    top: 3.6rem;
    overflow: auto;
}
.rightBar::-webkit-scrollbar{
    /* display: none; */
}
.rightBar-container{
    width: 90%;
}

@media(max-width:1024px){
    .rightBar-container{
        width: 90%;
    }
}

@media(max-width:768px){
    .rightBar{
        display: none;
    }
}