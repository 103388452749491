.dropdown-menu ul li{
    display: block;
    flex-direction: column; 
    transition: all linear 0.3s;
    line-height: 30px;
    color: #000000;
    font-size: 0.8rem;
  }
  
  .dropdown-menu ul li:hover {
    cursor: pointer;
    color: var(--color-primary);
    /* border-radius: 5px; */
    /* padding: 2px; */
  }
  
  .dropdown-menu {
    padding: 5px;
    background: #fff;
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    transition: all linear 0.3s;
  }